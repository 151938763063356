export async function $update<T extends ApiEntity>({
  id,
  instance,
  resourceName,
  values,
  method = 'PUT',
  deserialize = true,
}: {
  id?: id
  instance?: T // TODO: get rid of this, we should just pass the id and values
  resourceName: ResourceName
  // TODO:
  // - rename to `attributes`
  // - add type, Record<string, any> or Record<any, any>?
  values?: any
  method?: 'PUT' | 'PATCH' | 'POST' | 'DELETE'
  deserialize?: boolean
}) {
  const snakedResourceName = useSnakeCase(resourceName)
  const endpoint = endpoints[snakedResourceName]

  if (!endpoint) {
    throw new Error(`$update ${resourceName} endpoint not found`)
  }

  id ||= instance?.id
  const path = id ? `${endpoint}${id}` : endpoint

  const data = {
    data: {
      type: snakedResourceName,
      attributes: values,
    },
  }

  // was JSONAPIResponse<T>, but I'm not really sure if that doesn't break the legacy behavior
  // without the deserialize option
  return await $api<T>(path, {
    method: method,
    body: data,
    asFormData: true,
    deserialize,
  })
}
