export const endpoints: Record<ResourceName, EndpointPath> = {
  non_profit: '/api/v1/organizations/',
  notification_template: '/api/v1/notification_templates/',
  organization: '/api/v1/organizations/',
  page: '/api/v1/cms/pages/',
  user: '/api/v1/current_user/',
  volunteer_profile_reply: '/api/v1/volunteer_profile_replies/',
  volunteer_center: '/api/v1/organizations/',
  stock_image: '/api/v1/stock_images/',
}
